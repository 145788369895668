.featured-blog-card {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 6px solid #d2043b !important;
}

.featured-blog-card img {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
